@import '../../styles/util';

.Item {
  container: item / inline-size;
  background-color: var(--color-4);
  border-radius: var(--border-radius-2);
  display: flex;
  flex-direction: column;
  isolation: isolate;

  --padding: var(--spacing-2);
  @supports (font-size: 1cqw) {
    --padding: clamp(var(--spacing-1), 6cqw, var(--spacing-2));
  }

  &.is-preview {
    pointer-events: none;
  }

  &.placeholder {
    aspect-ratio: 286 / 330;
    background-color: #f6f6f6;
  }

  /* Theme variations */
  &.theme-0 {
    background-color: var(--color-white);
  }

  &.theme-1 {
    background-color: var(--color-1);
  }

  &.theme-2 {
    background-color: var(--color-2);
  }

  &.theme-3 {
    background-color: var(--color-3);
  }

  &.theme-4 {
    background-color: var(--color-4);
  }

  &.theme-5 {
    background-color: var(--color-5);
  }

  &.inaccessible {
    filter: grayscale(100%);
    pointer-events: none;

    .ItemBody {
      opacity: 0.3;
    }
  }
}

.ItemImage {
  --background: var(--palette-1);

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  aspect-ratio: 16 / 9;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: var(--border-radius-2) var(--border-radius-2) 0 0;

  // Palette colors
  &.palette-1 {
    --background: var(--palette-1);
  }
  &.palette-2 {
    --background: var(--palette-2);
  }
  &.palette-3 {
    --background: var(--palette-3);
  }
  &.palette-4 {
    --background: var(--palette-4);
  }
  &.palette-5 {
    --background: var(--palette-5);
  }
  &.palette-6 {
    --background: var(--palette-6);
  }
  &.palette-7 {
    --background: var(--palette-7);
  }
  &.palette-8 {
    --background: var(--palette-8);
  }

  // Unavailable badge
  .Badge {
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #fff;
    font-weight: 400;
    color: #8e8e8e;
    border: 1px solid #c4c4c4;
    border-radius: 0.6rem;
    transform: translate(-50%, 46%);
    z-index: 1;
    white-space: nowrap;
    line-height: 1.3rem;

    font-size: 1.4rem;
    padding: 0.6rem 1.2rem 0.7rem 1.2rem;
    @supports (font-size: 1cqw) {
      font-size: clamp(1rem, 5cqw, 1.4rem);
      padding: clamp(0.4rem, 2.5cqw, 0.9rem) clamp(0.2rem, 5cqw, 1.2rem)
        clamp(0.4rem, 2.5cqw, 0.9rem) clamp(0.2rem, 5cqw, 1.2rem);
    }
  }

  // Profile image
  picture {
    --default-size: 12rem;

    --size: var(--default-size);
    @supports (font-size: 1cqw) {
      --size: clamp(6rem, 44cqw, var(--default-size));
    }

    display: block;
    border-radius: 50%;
    border: 1px solid #fff;
    height: var(--size);
    width: var(--size);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.ItemBody {
  --p2: calc(var(--padding) * 1.25);

  padding: var(--p2) var(--padding);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: var(--p2);

  div {
    display: flex;
    flex-direction: column;
    gap: var(--padding);

    &:last-child {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      a {
        display: flex;
        gap: var(--padding);
        align-items: center;
        overflow: hidden;

        span {
          flex-shrink: 0;
        }

        strong {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      button {
        flex-shrink: 0;
      }
    }
  }

  .Medal {
    --width: calc(var(--padding) * 1.75);
    position: absolute;
    top: 0;
    right: var(--padding);
    background-color: var(--color-5);
    width: var(--width);
    height: calc(var(--width) * 1.8);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      mask-image: url(/svg/icon_triangle_up.svg);
      mask-size: cover;
      mask-repeat: no-repeat;
      mask-position: bottom center;
      background-color: #fff;
      background-color: var(--color-4);
      height: calc(var(--width) / 2);
    }

    &::before {
      content: '';

      position: absolute;
      left: 20%;
      top: 18%;
      right: 20%;
      mask-image: url(/svg/icon_star.svg);
      mask-size: cover;
      mask-repeat: no-repeat;
      mask-position: center;
      background-color: var(--color-4);
      aspect-ratio: 127 / 121;
    }
  }

  a {
    text-decoration: none;
  }

  p {
    line-height: 1.4;

    font-size: 1.8rem;
    @supports (font-size: 1cqw) {
      font-size: clamp(1.2rem, 7cqw, 1.8rem);
    }
  }

  div a {
    font-weight: 600;

    font-size: 1.6rem;
    @supports (font-size: 1cqw) {
      font-size: clamp(1.2rem, 8cqw, 1.6rem);
    }
  }

  &:has(.Medal) p {
    padding-right: calc(var(--padding) * 2);
  }

  small {
    color: var(--color-1);
    display: block;

    font-size: 1.3rem;
    @supports (font-size: 1cqw) {
      font-size: clamp(1rem, 7cqw, 1.3rem);
    }
  }

  h3 {
    text-wrap: balance;

    a {
      color: var(--color-black);
      font-weight: 700;

      font-size: 2.3rem;
      line-height: 2.7rem;
      @supports (font-size: 1cqw) {
        font-size: clamp(1.5rem, 8cqw, 2.3rem);
        line-height: clamp(1.9rem, 10cqw, 2.7rem);
      }

      @include line-clamp(2);
    }
  }
}
