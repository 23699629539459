@import '../../styles/util';

.Item {
  container: item / inline-size;

  --padding: var(--spacing-2);
  @supports (font-size: 1cqw) {
    --padding: clamp(var(--spacing-1), 5cqw, var(--spacing-2));
  }

  --p2: calc(var(--padding) * 1.5);

  background-color: var(--color-4);
  border-radius: var(--border-radius-2);
  overflow: hidden;
  display: grid;
  cursor: pointer;

  /* Theme variations */
  &.theme-0 {
    background-color: var(--color-white);
  }

  &.theme-1 {
    background-color: var(--color-1);
  }

  &.theme-2 {
    background-color: var(--color-2);
  }

  &.theme-3 {
    background-color: var(--color-3);
  }

  &.theme-4 {
    background-color: var(--color-4);
  }

  &.theme-5 {
    background-color: var(--color-5);
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: var(--padding);
    padding: var(--p2) var(--padding);
  }

  picture {
    padding-bottom: var(--padding);
  }

  img {
    mix-blend-mode: multiply;

    max-width: 16rem;
    max-height: 16rem;
    @supports (font-size: 1cqw) {
      max-width: 60cqw;
    }
  }

  h3 {
    text-wrap: balance;

    font-size: 2.3rem;
    line-height: 2.7rem;
    @supports (font-size: 1cqw) {
      font-size: clamp(1.5rem, 8cqw, 2.3rem);
      line-height: clamp(1.9rem, 10cqw, 2.7rem);
    }

    a {
      color: var(--color-black);

      @include line-clamp(2);
    }
  }

  a {
    text-decoration: none;

    font-size: 1.6rem;
    @supports (font-size: 1cqw) {
      font-size: clamp(1.2rem, 8cqw, 1.6rem);
    }
  }
}
