.FavoriteButton {
  width: 2.4rem;
  height: 2.4rem;
  text-indent: -9999px;
  mask-image: url(/svg/icon/icon_heart_outline.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: var(--color-1);

  &[aria-pressed='true'] {
    mask-image: url(/svg/icon/icon_heart_fill.svg);
  }

  &:focus-visible {
    background-color: var(--color-2);
  }
}
