@import '../../styles/util';
@import '../../styles/variables';

.Item {
  container: item / inline-size;

  --padding-y: var(--spacing-3);
  --padding-x: var(--spacing-4);
  --max-image-height: 17rem;

  @media screen and (width >= $tablet-portrait) {
    --padding-x: var(--spacing-4);
    --max-image-height: 17rem;
  }

  @media screen and (width >= $tablet-landscape) {
    --padding-x: var(--spacing-5);
    --max-image-height: 20rem;
  }

  @media screen and (width >= $max-width-page) {
    --padding-y: var(--spacing-4);
    --padding-x: var(--spacing-6);
    --max-image-height: 23rem;
  }

  padding: var(--padding-y) var(--padding-x);
  background-color: var(--color-4);
  border-radius: var(--border-radius-2);
  text-align: center;
  min-height: 20rem;

  gap: var(--spacing-4);
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  & > * {
    flex-basis: 100%;
  }

  picture {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      min-height: 15rem;
      max-height: 23rem;
      max-height: var(--max-image-height);
    }
  }

  div {
    gap: var(--spacing-2);
    display: flex;
    flex-direction: column;

    @media screen and (width >= $tablet-portrait) {
      gap: var(--spacing-3);
    }
  }

  &.arrow-left,
  &.arrow-right {
    --color: var(--color-3);
    --arrow-size: 4rem;
    background-color: var(--color);

    @media only screen and (width >= $tablet-portrait) {
      flex-direction: row;
      flex-flow: row-reverse;
    }

    &::after {
      --offset: calc((var(--arrow-size) * -1));
      content: '';
      position: absolute;
      bottom: calc((var(--offset) + 1px));
      width: 0;
      height: 0;
      border-top: 0 solid transparent;
      border-bottom: var(--arrow-size) solid transparent;
    }
  }

  &.arrow-left {
    text-align: left;
    margin-bottom: var(--arrow-size);

    &::after {
      left: var(--padding-x);
      border-left: var(--arrow-size) solid var(--color);
    }
  }

  &.arrow-right {
    text-align: right;
    margin-bottom: var(--arrow-size);

    &::after {
      right: var(--padding-x);
      border-right: var(--arrow-size) solid var(--color);
    }
  }
}
