@import '../../styles/variables';

.Grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-2);
}

@media only screen and (width >= 380px) {
  .Grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (width >= $tablet-landscape) {
  .Grid {
    gap: var(--spacing-3);
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
