@use 'sass:math';

$line-width: 12px;
$line-width-2: ($line-width * 2);
$line-offset: math.hypot($line-width-2, $line-width-2) * -1;

.Item {
  position: relative;
}

.Loader {
  opacity: 0;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.6) 0px,
    rgba(255, 255, 255, 0.6) $line-width,
    rgba(255, 255, 255, 0.7) $line-width,
    rgba(255, 255, 255, 0.7) $line-width-2
  );
  background-repeat: repeat;
  background-attachment: fixed;
  animation: moveLines 300ms linear infinite;

  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: bold;
  color: var(--color-1);
  transition: opacity 300ms ease-in-out;

  span {
    background-color: #fff;
    width: 13rem;
    height: 13rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12rem;
    transform: translateY(0);
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    opacity: 0;
  }

  &.loading {
    opacity: 1;

    span {
      transform: translateY(-1.6rem);
      opacity: 1;
    }
  }
}

@keyframes moveLines {
  from {
    background-position-x: 0;
  }

  to {
    background-position: $line-offset;
  }
}
